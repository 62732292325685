import React from 'react';

import './menu.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatableText } from '../language';
import { Nav } from 'react-bootstrap';
import { Link } from 'gatsby';

export default class MobileMenu extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <div className={'mobile-sidebar-menu d-flex flex-column ' + (this.props.show ? 'show-mobile-menu' : '')}>
        <Nav.Link as={Link} to="/home" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'What Is VABYSMO',
              french: 'Qu’est-ce que VABYSMO'
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/how-it-works" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'How VABYSMO Works'
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/get-vabysmo" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'Get VABYSMO'
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/faq" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'FAQs'
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/patient-resources" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'Patient Resources'
            }}
          />
        </Nav.Link>

        <Nav.Link as={Link} to="/community-support" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'Community Support',
              french: 'Soutien communautaire'
            }}
          />
        </Nav.Link>
        
        <Nav.Link href="https://www.rochehub.co.nz" target="_blank" onClick={this.context.closeMobileMenu}>
          Healthcare Professionals
        </Nav.Link>
      </div>
    );
  }
}
