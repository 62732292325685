import React from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Footer from './footer';
import Helmet from 'react-helmet';
import MainHeader from './main-header';
import PropTypes from 'prop-types';
import { BackToTopBtn } from './back-to-top-btn';
import SSRPage from 'src/components/ssr-page';

const isBrowser = typeof window !== 'undefined';

export default function Layout({ title, showNavbar, _rpgCode, className, children, showBackToTop = true }) {
  if (!isBrowser){
    return <SSRPage />;
  }

  return (
    <div className={className}>
      <AppStateContext.Consumer>
        {({ language }) => (
          <>
            <Helmet title={title[language] + ' | Vabysmo NZ'} meta={[{ name: 'robots', content: 'index,follow' }]}>
              <html lang='en-nz'/>

              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                data-language="en-NZ"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script="5d0b62db-363a-48e2-9d72-c09d5036150b" >

              </script>
              <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

              <body />
            </Helmet>
            <MainHeader siteTitle={title[language] + ' | Vabysmo'} showNavbar={showNavbar} />
          </>
        )}
      </AppStateContext.Consumer>
      <main id="main-content">
        {children}
        {showBackToTop ? <BackToTopBtn /> : ''}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  title: PropTypes.object.isRequired,
  showNavbar: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Layout.defaultProps = {
  showNavbar: false
};
