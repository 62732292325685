import React from 'react';
import './main-header.scss';

import { TranslatableText, TranslatedBlock } from './language';
import { Link } from 'gatsby';
import SkipToMain from './skip-to-main';
import FontSizeChanger from './font-size-changer.js';
import { default as logo_Vabysmo } from '../images/logo_Vabysmo.jpg';
import { default as Roche_logo } from '../images/Roche.svg';

import PropTypes from 'prop-types';
import Navbar from './navbar';

export default class MainHeader extends React.Component {
  render() {
    return (
      <header className={'main-header'}>
        <div className={'menu-wrapper container'}>
          <div className={'row align-items-center '}>
            <h1 className={'branding col-8 col-md order-1'}>
              <Link to="/" className={'custom-logo-link'}>
                <TranslatedBlock language="english">
                  <img src={logo_Vabysmo} alt="Vabysmo logo" className={'logo-img img-fluid'} />
                </TranslatedBlock>
                <span className={'site-name'}>{this.props.siteTitle}</span>
              </Link>
            </h1>
            <SkipToMain />
            <div className={'col col-xl-auto order-2 order-xl-3'}>
              <div className={'font-size-changer-container d-none d-md-flex'}>
                <span className={'text-size-label'}>
                  <TranslatableText
                    dictionary={{
                      english: 'Text Size:'
                    }}
                  />
                </span>
                <FontSizeChanger
                  className={'font-size-changer'}
                  targets={['.nav-link', 'section', 'main', '.disclaimer', '.quote']}
                  options={{ stepSize: 1, range: 5 }}
                />
              </div>
            </div>
            <div className={'col-3 col-md-auto col-xl-1 offset-sm-0 order-2 order-xl-3 d-flex justify-content-end mt-10'}>
              <a target="_blank" rel="noreferrer" href="https://roche.co.nz" title="roche.co.nz">
                <img src={Roche_logo} alt="Roche logo" className={'roche-logo img-fluid'} />
              </a>
            </div>
          </div>
        </div>
        {this.props.showNavbar && <Navbar />}
      </header>
    );
  }
}

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
  showNavbar: PropTypes.bool
};

MainHeader.defaultProps = {
  siteTitle: '',
  showNavbar: true
};
