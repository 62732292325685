import React, { useState, useEffect } from 'react';
import './back-to-top-btn.scss';
import { TranslatableText } from './language';

export const BackToTopBtn = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    const showBtnBreakpoint = 600;
    if (scrolled > showBtnBreakpoint){
       setVisible(true);
    }else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
  });

  return (
    <button className="back-to-top" onClick={scrollToTop} style={{ display: visible ? 'flex' : 'none' }}>
      <span>^</span>
      <span>
        <TranslatableText
          dictionary={{
            english: 'TOP'
          }}
        />
      </span>
    </button>
  );
};
