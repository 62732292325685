import React from 'react';

import './navbar.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatableText } from './language';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { navigate } from 'gatsby';
import MobileNavBar from './mobile/navbar';

export default class Tab extends React.Component {
  static contextType = AppStateContext;

  navigateParent(_e, href) {
    navigate(href);
  }
  navigateParentOnEnter(e, href) {
    if (e.key === 'Enter') {
      navigate(href);
    }
  }
  navigateChild(e, href) {
    e.stopPropagation();
    navigate(href);
  }
  render() {
    const context = this.context;
    const pageName = context.page;
    return (
      <Navbar bg="light" expand="lg" className="top-navbar">
        <Container className={'d-none d-lg-flex'}>
          <Nav className="me-auto justify-content-between w-100">
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/home/')}
              onClick={e => this.navigateParent(e, '/home/')}
              className={`${pageName === 'home' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'What Is VABYSMO'
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/how-it-works/')}
              onClick={e => this.navigateParent(e, '/how-it-works/')}
              className={`${pageName === 'how-it-works' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'How VABYSMO Works'
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/get-vabysmo/')}
              onClick={e => this.navigateParent(e, '/get-vabysmo/')}
              className={`${pageName === 'get-vabysmo' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'Get VABYSMO'
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/faq/')}
              onClick={e => this.navigateParent(e, '/faq/')}
              className={`${pageName === 'faq' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'FAQs'
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/patient-resources/')}
              onClick={e => this.navigateParent(e, '/patient-resources/')}
              className={`${pageName === 'patient-resources' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'Patient Resources'
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/community-support/')}
              onClick={e => this.navigateParent(e, '/community-support/')}
              className={`${pageName === 'community-support' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'Community Support'
                }}
              />
            </Nav.Link>
           
            <Nav.Link href="https://www.rochehub.co.nz" target="_blank" className="">
                Healthcare Professionals
            </Nav.Link>
          </Nav>
        </Container>
        <MobileNavBar />
      </Navbar>
    );
  }
}
