import React from 'react';
import './footer.scss';

import { AppStateContext } from '../contexts/app-state-provider.context';
import { TranslatableText, TranslatedBlock } from './language';

export default class Footer extends React.Component {
  static contextType = AppStateContext;

  render() {
    const rpgCodeDict = {
      english: 'M-NZ-00000742-v4.0/NP21519/OCT2024. This site was last updated Oct 2024'
     
    };
    
    const rpgCode = rpgCodeDict ? (
      <div className="container rpg-code-container">
        <div className={'rpg-code'}>
          <TranslatableText dictionary={rpgCodeDict} />
        </div>
      </div>
    ) : null;

    return (
      <AppStateContext.Consumer>
        {() => (
          <footer id="colophon" className="site-footer login-footer pb-4">
            <TranslatedBlock language="english">
              <div className="container">
                <div className="row">
                  <div className="d-flex flex-column flex-sm-row footer-nav col-12 pt-5 pb-2">
                    <ul>
                    <li>
                        <a href="/contact-us/"  rel="noreferrer" title="CONTACT US">CONTACT US</a>
                      </li>
                      <li>
                        <a href="https://www.roche.co.nz/privacy/" target="_blank" rel="noreferrer" title="PRIVACY POLICY">PRIVACY POLICY</a>
                      </li>
                      <li>
                        <a href="https://www.roche.co.nz/legal-statement/" target="_blank" title="LEGAL STATEMENT" rel="noreferrer">LEGAL STATEMENT</a>
                      </li>
                      <li>
                        <button className="ot-sdk-show-settings">COOKIE PREFERENCES</button>
                       {/* <a href="https://www.roche.co.nz/cookie-notice/ " target="_blank" rel="noreferrer">COOKIE PREFERENCES</a>*/}
                      </li>
                     
                    </ul>
                  </div>
                  <div className="col-12  d-flex flex-column text-justify justify-content-between">
                    <p>
                    © 2024, Roche Products (New Zealand) Limited, Auckland. For more information about Roche, please visit www.roche.co.nz.
                     This site contains information on VABYSMO which is intended for New Zealand audiences.
                    <br/> Please be aware that we do not take any responsibility for accessing such information which may not comply with any legal process,
                     regulation, registration or use in the country of your origin.
                    Access to this site is subject to the terms in our Legal and Privacy Statement.
                    You accept these terms by continuing to access this site.
                    </p>
                  </div>
                  <div className="col-12 mt-3">{rpgCode}</div>
                </div>
              </div>
            </TranslatedBlock>
          </footer>
        )}
      </AppStateContext.Consumer>
    );
  }
}
